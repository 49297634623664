<template>
  <div class="confirm-inscriptions" v-if="hasAllPerms(['portal.view_', 'youth.view_seanceinscription'])">
    <page-header title="Confirmation des inscriptions" icon="fa fa-check"></page-header>
    <div class="sub-header" v-show="initialLoadingDone">
      <b-row>
        <b-col cols="5">
          <h2>
            <counter-label :counter="inscriptionsCount" label="inscription"></counter-label>
          </h2>
        </b-col>
        <b-col cols="3">
        </b-col>
        <b-col cols="4" class="text-right">
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="9">
          <div class="filter-bar">
            <div class="filter-bar-title">Filtrer les inscriptions</div>
              <b-row>
                <b-col cols="3">
                  <b-form-group
                    id="status-filter-group"
                    label="État"
                    label-for="status-filter"
                  >
                    <b-form-select
                      id="status-filter"
                      v-model="selectedStatusFilterId"
                      @change="onStatusFilterChanged"
                    >
                      <b-form-select-option
                        v-for="statusFilter of statusFilters"
                        :key="statusFilter.id"
                        :value="statusFilter.id"
                      >
                        {{ statusFilter.name }}
                      </b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                  <b-form-group
                    id="paid-filter-group"
                    label="Paiement"
                    label-for="paid-filter"
                  >
                    <b-form-select
                      id="paid-filter"
                      v-model="selectedPaidFilterId"
                      @change="onPaidFilterChanged"
                    >
                      <b-form-select-option
                        v-for="paidFilter of paidFilters"
                        :key="paidFilter.id"
                        :value="paidFilter.id"
                      >
                        {{ paidFilter.name }}
                      </b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <div v-if="false">
                    <b-form-group
                      id="youth-homes-select-group"
                      :label="youthHomeLabel"
                      label-for="youth-homes"
                    >
                      <youth-home-selector
                        :initial-id="youthHomeInitialId"
                        @change="onYouthHomeChanged($event.youthHome)"
                        @init="youthHomeFilter=$event.youthHome"
                      >
                      </youth-home-selector>
                    </b-form-group>
                  </div>
                  <div v-else>
                    <youth-homes-portal-selector
                      :initial-ids="initialYouthHomes"
                      @change="onYouthHomesChanged($event.youthHomes, $event.initial)"
                    ></youth-homes-portal-selector>
                  </div>
                </b-col>
                <b-col cols="3">
                  <b-form-group
                    id="period-select-group"
                    label="Période"
                    label-for="period"
                  >
                    <seance-periods-selector
                      :initial-id="periodInitialId"
                      @change="onPeriodChanged($event.period)"
                      @init="periodFilter=$event.period"
                    >
                    </seance-periods-selector>
                  </b-form-group>
                  <b-form-group
                    id="kind-group"
                    label="Type"
                    label-for="kind-filter"
                  >
                    <b-form-select
                      id="kind-filter"
                      v-model="selectedKindFilterId"
                      @change="onRefresh"
                    >
                      <b-form-select-option
                        v-for="kindFilter of kindFilters"
                        :key="kindFilter.id"
                        :value="kindFilter.id"
                      >
                        {{ kindFilter.name }}
                      </b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                  <div v-for="field of fieldsFilters" :key="field.id">
                    <b-form-group
                      :id="'fieldsFilterGr' + field.id"
                      :label="field.name"
                      label-for="'fieldsFilter' + field.id"
                    >
                      <b-form-select
                        :id="'fieldsFilter' + field.id"
                        :value="getFieldFilterValue(field)"
                        @change="setFieldFilterValue(field, $event)"
                      >
                        <b-form-select-option
                          v-for="value of field.values"
                          :value="value.id"
                          :key="value.id"
                        >
                          {{ value.name }}
                        </b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                  </div>
                </b-col>
              </b-row>
          </div>
        </b-col>
        <b-col>
          <div class="filter-bar-title">Plus de filtres</div>
          <b-row>
            <b-col>
              <b-form-group
                id="date-select-group"
                label="Date"
                label-for="dateFilter"
                label-cols-sm="3"
                description="Date de séance"
              >
                <b-form-input
                  type="date"
                  v-model="dateFilter"
                  id="dateFilter"
                  @change="onSeanceDateChanged($event)"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                id="member-filter-group"
                label="Famille"
                label-for="familyFilter"
                label-cols-sm="3"
              >
                <family-typeahead
                  :initial-id="familyInitialId"
                  @change="onFamilyChanged($event.family)"
                  @init="familyFilter=$event.family"
                >
                </family-typeahead>
              </b-form-group>
              <b-form-group
                id="member-filter-group"
                label="Enfant"
                label-for="individualFilter"
                label-cols-sm="3"
              >
                <individual-typeahead
                  :initial-id="individualInitialId"
                  @change="onIndividualChanged($event.individual)"
                  @init="individualFilter=$event.individual"
                >
                </individual-typeahead>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                id="seance-name-filter-group"
                label="Nom de séance"
                label-for="seanceNameFilter"
                label-cols-sm="3"
              >
                <b-form-input
                  id="seanceNameFilter"
                  v-model="seanceNameFilter"
                  @change="onSeanceNameFilterChanged"
                  :class="seanceNameFilter ? 'highlighted' : ''"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="showOrderBy">
            <b-col>
              <b-form-group
                id="order-filter-group"
                label="Tri"
                label-for="orderBy"
                label-cols-sm="3"
              >
                <b-form-select id="orderBy" v-model="orderBy" @change="onOrderByChanged">
                  <b-form-select-option value="">Date de mise en attente</b-form-select-option>
                  <b-form-select-option value="created_on">Date d'inscription</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <div class="verification-line">
        <b-row>
          <b-col cols="3">
            <div class="verification-label">
              <counter-label
                :counter="acceptedInscriptions.length"
                label="inscription acceptée"
                label-n="inscriptions acceptées"
              >
              </counter-label>
              <br />
              <counter-label
                :counter="refusedInscriptions.length"
                label="inscription refusée"
                label-n="inscriptions refusées"
              >
              </counter-label>
              <br />
              <counter-label
                :counter="waitingInscriptions.length"
                label="inscription en attente"
                label-n="inscriptions en attente"
              >
              </counter-label>
              <br />
              <counter-label
                :counter="acceptedCancellations.length"
                label="annulation acceptée"
                label-n="annulations acceptées"
              >
              </counter-label>
              <br />
              <counter-label
                :counter="refusedCancellations.length"
                label="annulation facturée"
                label-n="annulation facturées"
              >
              </counter-label>
            </div>
          </b-col>
          <b-col cols="4">
            <div class="warning-message" v-if="allowWaitingList && hasToBeRefused">
              <b>Il existe des inscriptions "En liste d'attente" pour des séances passées.</b>
              <br />
              Vous devez refuser ces inscriptions pour permettre leur remboursement.
              <br />
              <div class="text-right">
                <a class="btn btn-xs btn-secondary"
                   href
                   @click.prevent="showToBeRefused()"
                >
                  Voir les inscriptions à refuser
                </a>
              </div>
            </div>
          </b-col>
          <b-col cols="5" class="text-right">
            <a class="btn btn-primary" @click.prevent="onConfirmInscriptions()"
               :class="{ disabled: noChange}"
            >
              Confirmer
            </a>
            <div class="checks-links">
              <a href @click.prevent="onAcceptAll()">Tout accepter</a>
              <a href @click.prevent="onRefuseAll()">Tout refuser</a>
              <a href @click.prevent="onWaitingAll()" v-if="allowWaitingList">
                Tout en attente
              </a>
              <a href @click.prevent="onUncheckAll()">Tout décocher</a>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <loading-gif :loading-name="loadingName"></loading-gif>
    <div class="legend-header">
      <limits-indicator-legend></limits-indicator-legend>
    </div>
    <b-row v-if="!isLoading(loadingName)">
      <b-col cols="3">
        <div class="ins-summary">
          <div><b>Résumé des inscriptions</b></div>
          <loading-gif :loading-name="limitIndicatorLoadingName"></loading-gif>
          <div v-for="seance of seances" :key="seance.id">
            <seance-inscription-limits-indicator
              :seance="seance"
              :seance-inscription-limits="getSeanceInscriptionLimits(seance)"
              @selected="onLimitSelected"
              :selection="limitSelection"
              v-show="!isLoading(limitIndicatorLoadingName)"
            >
            </seance-inscription-limits-indicator>
          </div>
        </div>
      </b-col>
      <b-col cols="9">
        <b-row v-if="limitSelection">
          <b-col cols="3"><b>{{ limitSelection.name }}</b></b-col>
          <b-col class="text-right" cols="3">
            <counter-label :counter="displayedInscriptions.length" label="inscription"></counter-label>
          </b-col>
          <b-col class="text-right" cols="3">
              <a href @click.prevent="resetLimit()">
              Tout afficher
            </a>
          </b-col>
        </b-row>
        <b-row v-else>
          <b-col>
            <b v-if="showRefused">
              Inscriptions refusées pour les séances
              <span v-if="dateFilter">du {{ dateFilter|dateToString }}</span>
              <span v-else>à venir</span>
            </b>
            <b v-else-if="showWaitingList">
              Inscriptions en attente pour les séances
              <span v-if="dateFilter">du {{ dateFilter|dateToString }}</span>
              <span v-else>à venir</span>
            </b>
            <b v-else-if="showRefund">
              Inscriptions à rembourser pour les séances
              <span v-if="dateFilter">du {{ dateFilter|dateToString }}</span>
              <span v-else>à venir</span>
            </b>
            <b v-else>Inscriptions à confirmer</b>
          </b-col>
        </b-row>
        <div v-if="inscriptions.length === 0">
          Aucune inscription à confirmer pour les critères sélectionnés.
          <ul>
            <li>{{ statusFilter }}</li>
            <li>{{ youthHomesFilterAsText }}</li>
            <li v-if="youthHomeFilter">{{ youthHomeFilter.name }}
              <span v-if="youthHomeFilter.schoolYear.id">- {{ youthHomeFilter.schoolYear.name }}</span>
            </li>
            <li v-if="periodFilter">{{ periodFilter.name }}</li>
            <li>{{ paidFilter }}</li>
            <li>{{ kindFilter }}</li>
            <li v-if="dateFilter">{{ dateFilter | dateToString('dddd LL') }}</li>
            <li v-if="individualFilter">{{ individualFilter.firstAndLastName() }}</li>
            <li v-if="familyFilter">{{ familyFilter.entity.name }}: {{ familyFilter.entity.members }}</li>
            <li v-if="seanceNameFilter">Nom de séance contenant <b>{{ seanceNameFilter }}</b></li>
          </ul>
        </div>
        <table class="table table-striped table-responsive" v-if="!isLoading(loadingName)">
          <tr v-for="inscription in displayedInscriptions" :key="inscription.id">
            <td>
              {{ inscription.createdOn | dateToString('llll') }}
              <div v-if="inscription.cancelledOn" class="small">
                Annulée le {{ inscription.cancelledOn | dateToString('llll') }}
              </div>
              <div v-if="inscription.refusedOn && inscription.waiting" class="small">
                Mis en attente le {{ inscription.refusedOn | dateToString('llll') }}
              </div>
              <div v-if="inscription.refusedOn && !inscription.waiting" class="small">
                Refusé le {{ inscription.refusedOn | dateToString('llll') }}
              </div>
              <div>
                <div>
                  <span class="badge badge-dark">
                    {{ inscription.seance.youthHome.name }}
                  </span>&nbsp;
                  <span class="badge badge-dark">
                    {{ inscription.seance.period.name }}
                  </span>&nbsp;
                  <span class="badge badge-dark">
                    {{ inscription.seance.seanceType.name }}
                  </span>
                </div>
                <span class="badge badge-warning" v-if="inscription.isFree">
                  <i class="fa fa-check"></i> Inscription gratuite
                </span>
                <span class="badge badge-danger" v-else-if="!inscription.isPaid">
                  <i class="fa fa-warning"></i> Inscription non payée
                </span>
                <span class="badge badge-success" v-else>
                  <i class="fa fa-check"></i> Inscription payée
                </span>
              </div>
            </td>
            <td class="badges-holder">
              <span class="badge badge-warning" v-if="inscription.cancelled">Annulation</span>
              <span class="badge badge-danger" v-if="inscription.refused && !inscription.waiting">Refusée</span>
              <span class="badge badge-warning" v-if="inscription.refused && inscription.waiting">En attente</span>
              <span class="badge badge-info" v-if="inscription.preference">Choix n°{{ inscription.preference }}</span>
              <a
                href @click.prevent="onSeanceDateChanged(inscription.seance.date)"
                class="bold"
              >{{ inscription.seance.date | dateToString('dddd LL') }}</a>
              <span class="bold">{{ inscription.seance.getShortName() }}</span>
              <div>
                <a
                  :href="getLinkToEntityUrl(inscription.entity.id, inscription.individual)"
                  @click.prevent="onIndividualClicked(inscription.individual)"
                  class="bold"
                >
                  {{ inscription.individual.firstAndLastName() }}
                </a>
                <div v-if="inscription.entity && inscription.entity.id" class="family-info">
                  <a
                    :href="getLinkToEntityUrl(inscription.entity.id)"
                    @click.prevent="onFamilyClicked(inscription)"
                  >{{ inscription.entity.name }}</a>
                  &nbsp;
                  <router-link
                    :href="getLinkToEntityUrl(inscription.entity.id)"
                    :to="getLinkToEntity(inscription.entity.id)"
                    class="btn btn-secondary btn-xs"
                  >
                    Fiche famille
                  </router-link>
                </div>
                <div v-if="inscription.entity && getEntityDueInvoice(inscription)">
                  <div class="badge badge-danger">
                    À payer {{ getEntityDueInvoice(inscription) | currency }}
                  </div>
                </div>
                <div
                  v-for="field of getInscriptionFields(inscription)"
                  :key="field.id"
                  class="badge badge-dark"
                >
                  {{ field.name }}: {{ field.value }}
                </div>
              </div>
            </td>
            <td style="min-width: 150px">
              <span v-if="inscription.ageGroup">{{ inscription.ageGroup.name }}</span>
              <span v-else>-</span>
              <single-inscription-limits-indicator
                :inscription="inscription"
                :seance-inscription-limits="getSeanceInscriptionLimits(inscription.seance)"
                v-show="!isLoading(limitIndicatorLoadingName)"
              >
              </single-inscription-limits-indicator>
            </td>
            <td class="text-right actions-holder">
              <b-checkbox
                v-if="isAcceptedVisible(inscription)"
                :checked="isInscriptionAccepted(inscription)"
                :value="true"
                :unchecked-value="false"
                @change="onAcceptInscription(inscription, $event)"
              >
                Accepter
              </b-checkbox>
              <b-checkbox
                v-if="inscription.cancelled"
                :checked="isCancellationAccepted(inscription)"
                :value="true"
                :unchecked-value="false"
                @change="onAcceptCancellation(inscription, $event)"
              >
                Rembourser
              </b-checkbox>
              <b-checkbox
                v-if="isAcceptedVisible(inscription)"
                :checked="isInscriptionRefused(inscription)"
                :value="true"
                :unchecked-value="false"
                @change="onRefuseInscription(inscription, $event)"
              >
                Refuser
              </b-checkbox>
              <b-checkbox
                v-if="inscription.cancelled"
                :checked="isCancellationRefused(inscription)"
                :value="true"
                :unchecked-value="false"
                @change="onRefuseCancellation(inscription, $event)"
              >
                Facturer
              </b-checkbox>
              <b-checkbox
                v-if="!inscription.cancelled && !inscription.refused && hasWaitingList(inscription)"
                :checked="isInscriptionWaiting(inscription)"
                :value="true"
                :unchecked-value="false"
                @change="onWaitingInscription(inscription, $event)"
              >
                En attente
              </b-checkbox>
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapMutations } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import CounterLabel from '@/components/Controls/CounterLabel'
import LoadingGif from '@/components/Controls/LoadingGif'
import IndividualTypeahead from '@/components/Individual/IndividualTypeahead'
import PageHeader from '@/components/Layout/PageHeader'
import SeancePeriodsSelector from '@/components/Seances/SeancePeriodsSelector'
import YouthHomeSelector from '@/components/Seances/YouthHomeSelector'
import YouthHomesPortalSelector from '@/components/Seances/YouthHomesPortalSelector'
import SeanceInscriptionLimitsIndicator from '@/components/SeanceLimits/SeanceInscriptionLimitsIndicator.vue'
import SingleInscriptionLimitsIndicator from '@/components/SeanceLimits/SingleInscriptionLimitsIndicator.vue'
import LimitsIndicatorLegend from '@/components/SeanceLimits/LimitsIndicatorLegend.vue'
import FamilyTypeahead from '@/components/Entity/FamilyTypeahead'
import { makeChoice } from '@/types/base'
import { makeFamily } from '@/types/families'
import { FieldType } from '@/types/fields'
import { makeIndividual } from '@/types/people'
import { makePortalService } from '@/types/portal'
import { makeSeanceInscription, makeSeanceInscriptionLimit, PaidFilter, StatusFilter, KindFilter } from '@/types/youth'
import { BackendApi } from '@/utils/http'
import { distinctElements, existsIn } from '@/utils/arrays'
import { compareDays, compareNumbers } from '@/utils/sorting'
import store from '@/store'
import router from '@/router'

export default {
  name: 'ConfirmInscriptions',
  mixins: [BackendMixin],
  components: {
    FamilyTypeahead,
    CounterLabel,
    IndividualTypeahead,
    LoadingGif,
    PageHeader,
    SeancePeriodsSelector,
    YouthHomeSelector,
    YouthHomesPortalSelector,
    SeanceInscriptionLimitsIndicator,
    SingleInscriptionLimitsIndicator,
    LimitsIndicatorLegend,
  },
  data() {
    return {
      loadingName: 'confirm-inscriptions',
      limitIndicatorLoadingName: 'confirm-limits',
      inscriptions: [],
      inscriptionsCount: 0,
      initialLoadingDone: false,
      acceptedInscriptions: [],
      refusedInscriptions: [],
      waitingInscriptions: [],
      acceptedCancellations: [],
      refusedCancellations: [],
      limits: {},
      dateFilter: null,
      individualFilter: null,
      periodFilter: null,
      youthHomeFilter: null,
      youthHomesFilter: [],
      loaded: false,
      periodInitialId: 0,
      youthHomeInitialId: 0,
      individualInitialId: 0,
      familyInitialId: 0,
      familyFilter: null,
      paidFilters: [],
      kindFilters: [],
      selectedPaidFilterId: 0,
      selectedKindFilterId: 0,
      selectedStatusFilterId: StatusFilter.ToDo,
      services: [],
      initialYouthHomes: [],
      seanceLimits: new Map(),
      limitSelection: null,
      hasToBeRefused: false,
      entitiesWithDueInvoices: new Map(),
      hasInitialFilter: false,
      entityFields: new Map(),
      individualFields: new Map(),
      seanceNameFilter: '',
      allYouthHomes: [],
      orderBy: 'created_on',
      fieldsFilters: [],
      fieldsFiltersValues: new Map(),
    }
  },
  watch: {
    loading: function(newValue) {},
  },
  computed: {
    youthHomeLabel() {
      return store.getters.youthHomeLabel
    },
    allowWaitingList() {
      return this.youthHomesFilter.filter(elt => elt.allowWaitingList).length > 0
    },
    noChange() {
      return (
        (
          this.acceptedInscriptions.length +
          this.refusedInscriptions.length +
          this.waitingInscriptions.length +
          this.acceptedCancellations.length +
          this.refusedCancellations.length
        ) === 0
      )
    },
    displayedInscriptions() {
      if (this.limitSelection) {
        return this.inscriptions.filter(
          elt => {
            let match
            const seanceLimits = this.getSeanceInscriptionLimits(elt.seance)
            if (this.limitSelection.limit) {
              const seanceLimitsIds = seanceLimits.limits.map(elt => elt.id)
              match = existsIn([this.limitSelection.limit.id], seanceLimitsIds)
              if (match) {
                match = this.limitSelection.seanceDate === elt.seance.date
              }
              if (match && this.limitSelection.ageGroup) {
                match = (elt.ageGroup.id === this.limitSelection.ageGroup.groupId)
              }
            } else {
              match = elt.seance.id === this.limitSelection.seanceId
            }
            return match
          }
        )
      } else {
        return this.inscriptions
      }
    },
    youthHomesFilterAsText() {
      if (this.youthHomesFilter.length === this.services.length) {
        return 'Tous les centres'
      }
      return this.youthHomesFilter.map(elt => elt.name).join(', ') || 'Aucun centre'
    },
    paidFilter() {
      const matching = this.paidFilters.filter(elt => elt.id === this.selectedPaidFilterId)
      if (matching.length) {
        return matching[0].name
      }
      return ''
    },
    kindFilter() {
      const matching = this.kindFilters.filter(elt => elt.id === this.selectedKindFilterId)
      if (matching.length) {
        return matching[0].name
      }
      return ''
    },
    statusFilter() {
      const matching = this.statusFilters.filter(elt => elt.id === this.selectedStatusFilterId)
      if (matching.length) {
        return matching[0].name
      }
      return ''
    },
    showRefused() {
      return +this.selectedStatusFilterId === StatusFilter.Refused
    },
    showWaitingList() {
      return +this.selectedStatusFilterId === StatusFilter.Waiting
    },
    showRefund() {
      return +this.selectedStatusFilterId === StatusFilter.Refund
    },
    seances() {
      return this.inscriptions.reduce(
        (listOfSeances, currentInscription) => {
          if (!existsIn([currentInscription.seance.id], listOfSeances.map(elt => elt.id))) {
            listOfSeances.push(currentInscription.seance)
          }
          return listOfSeances
        },
        []
      ).sort(
        (seance1, seance2) => {
          let order = compareDays(seance1.date, seance2.date)
          if (order === 0) {
            order = compareNumbers(seance1.sortNumber(), seance2.sortNumber())
            if (order === 0) {
              order = compareNumbers(seance1.id, seance2.id)
            }
          }
          return order
        }
      )
    },
    showOrderBy() {
      return this.selectedStatusFilterId === StatusFilter.Waiting
    },
    statusFilters() {
      let statusFilters = [
        makeChoice({ id: StatusFilter.ToDo, name: 'À traiter', }),
        makeChoice({ id: StatusFilter.Refused, name: 'Refusées', })
      ]
      if (this.allowWaitingList || this.hasInitialFilter) {
        statusFilters = statusFilters.concat(
          [
            makeChoice({ id: StatusFilter.Waiting, name: 'En attente', }),
            makeChoice({ id: StatusFilter.Refund, name: 'En attente: à refuser', })
          ]
        )
      }
      return statusFilters
    },
  },
  async mounted() {
    await this.loadServices()
    const hasAutoInvoice = this.services.filter(elt => elt.autoInvoice).length > 0
    let paidFilters = [
      makeChoice({ id: PaidFilter.Paid, name: 'Inscriptions payées', }),
      makeChoice({ id: PaidFilter.Unpaid, name: 'Inscriptions à payer', }),
      makeChoice({ id: PaidFilter.All, name: 'Toutes les inscriptions', })
    ]
    let kindFilters = [
      makeChoice({ id: KindFilter.All, name: 'Tous', }),
      makeChoice({ id: KindFilter.Inscriptions, name: 'Inscriptions', }),
      makeChoice({ id: KindFilter.Cancellations, name: 'Annulations', })
    ]
    if (!hasAutoInvoice) {
      paidFilters = [paidFilters[2], paidFilters[0], paidFilters[1]]
    }
    this.paidFilters = paidFilters
    this.kindFilters = kindFilters
    await this.onLoaded()
    this.hasInitialFilter = (
      existsIn([this.selectedStatusFilterId], [StatusFilter.Waiting, StatusFilter.Refund])
    )
  },
  methods: {
    ...mapActions(['addError', 'addSuccess', 'addWarning']),
    ...mapMutations(['startLoading', 'endLoading']),
    onLimitSelected(event) {
      this.resetChecks()
      this.limitSelection = event
      this.loadLimits()
    },
    resetLimit() {
      this.resetChecks()
      this.limitSelection = null
      this.loadLimits()
    },
    hasWaitingList(inscription) {
      return inscription.seance.youthHome.allowWaitingList
    },
    async checkToBeRefused() {
      let url = '/api/youth/confirm-inscriptions-check/?'
      let youthHomesStr = ''
      if (this.youthHomeFilter) {
        youthHomesStr = this.youthHomeFilter.map(elt => ('' + elt.id)).join('-')
      }
      if (!youthHomesStr) {
        youthHomesStr = ''
      }
      url += '&youth_homes=' + youthHomesStr
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.hasToBeRefused = resp.data.count > 0
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async onLoaded() {
      if (this.$route.query.seance_date) {
        this.dateFilter = this.$route.query.seance_date
      }
      if (this.$route.query.period) {
        this.periodInitialId = +this.$route.query.period
      }
      if (this.$route.query.youth_home) {
        this.youthHomeInitialId = +this.$route.query.youth_home
      }
      if (this.$route.query.individual) {
        this.individualInitialId = +this.$route.query.individual
        this.individualFilter = makeIndividual({ id: this.individualInitialId, })
      }
      if (this.$route.query.family) {
        this.familyInitialId = +this.$route.query.family
        this.familyFilter = makeFamily(
          { id: this.familyInitialId, entity: { id: this.familyInitialId, }, }
        )
      }
      if (this.$route.query.youth_homes) {
        const queryYouthHomes = '' + this.$route.query.youth_homes
        this.initialYouthHomes = queryYouthHomes.split('-').map(elt => +elt)
      }
      this.youthHomesFilter = this.initialYouthHomes.map(value => { return { id: value, } })
      if (this.$route.query.paid) {
        this.selectedPaidFilterId = +this.$route.query.paid
      } else if (this.paidFilters.length) {
        this.selectedPaidFilterId = this.paidFilters[0].id
      }
      if (this.$route.query.kind) {
        this.selectedKindFilterId = +this.$route.query.kind
      } else if (this.kindFilters.length) {
        this.selectedKindFilterId = this.kindFilters[0].id
      }
      if (this.$route.query.status) {
        this.selectedStatusFilterId = +this.$route.query.status
      } else if (this.statusFilters.length) {
        this.selectedStatusFilterId = this.statusFilters[0].id
      }
      const that = this
      setTimeout(
        async function() {
          await that.loadElements(
            that.dateFilter, that.periodFilter, that.youthHomeFilter, that.youthHomesFilter, that.individualFilter,
            that.selectedPaidFilterId, that.familyFilter, that.selectedStatusFilterId, that.selectedKindFilterId,
            that.seanceNameFilter
          )
          await that.loadLimits()
          await that.loadEntitiesWithDueInvoices()
          await that.loadInscriptionFields()
          that.loaded = true
        },
        500
      )
      if (this.allowWaitingList) {
        await this.checkToBeRefused()
      }
    },
    async onFilterChanged(
      seanceDate, period, youthHome, youthHomes, individual, paidFilter, family, statusFilter, kindFilter,
      seanceNameFilter
    ) {
      if (this.loaded) {
        let queryArgs = { ...this.$route.query, }
        const periodId = period ? ('' + period.id) : ''
        const youthHomeId = youthHome ? ('' + youthHome.id) : ''
        const individualId = individual ? ('' + individual.id) : ''
        const familyId = family ? ('' + family.entity.id) : ''
        queryArgs.period = periodId
        queryArgs.youth_home = youthHomeId
        queryArgs.youth_homes = youthHomes.map(elt => ('' + elt.id)).join('-')
        queryArgs.individual = individualId
        queryArgs.family = familyId
        queryArgs.seance_date = seanceDate
        queryArgs.paid = '' + this.selectedPaidFilterId
        queryArgs.status = '' + this.selectedStatusFilterId
        queryArgs.kind = '' + this.selectedKindFilterId
        try {
          await this.$router.replace({ query: queryArgs, })
        } catch {
          // silent
        }
        await this.loadElements(
          seanceDate, period, youthHome, youthHomes, individual, paidFilter, family, statusFilter, kindFilter,
          seanceNameFilter
        )
        await this.loadLimits()
        await this.loadEntitiesWithDueInvoices()
        await this.loadInscriptionFields()
      }
    },
    async loadElements(
      seanceDate, period, youthHome, youthHomes, individual, paid, family, status, kind, seanceNameFilter
    ) {
      this.resetChecks()
      this.resetLimit()
      this.startLoading(this.loadingName)
      let url = '/api/youth/confirm-inscriptions/?'
      if (seanceDate) { url += '&seance_date=' + seanceDate }
      if (period) { url += '&period=' + period.id }
      if (youthHome) { url += '&youth_home=' + youthHome.id }
      let youthHomesStr = youthHomes.map(elt => ('' + elt.id)).join('-')
      if (!youthHomesStr) {
        youthHomesStr = '0'
      }
      url += '&youth_homes=' + youthHomesStr
      if (individual) { url += '&individual=' + individual.id }
      if (family) { url += '&entity=' + family.entity.id }
      url += '&paid=' + paid
      url += '&status=' + status
      url += '&kind=' + kind
      if (seanceNameFilter) {
        url += '&seance_name=' + seanceNameFilter
      }
      if (this.showOrderBy) {
        url += '&order=' + this.orderBy
      }
      let fieldIndex = 0
      for (const field of this.fieldsFilters) {
        const value = this.getFieldFilterValue(field)
        if (value) {
          url += '&field' + fieldIndex + '=' + field.id
          url += '&value' + fieldIndex + '=' + value
          fieldIndex += 1
        }
      }
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.initialLoadingDone = true
        this.inscriptionsCount = resp.data.length
        this.inscriptions = resp.data.map(elt => makeSeanceInscription(elt))
        this.resetChecks()
      } catch (err) {
        this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    isAcceptedVisible(inscription) {
      if (inscription.cancelled) {
        return false
      }
      if (inscription.refused) {
        return inscription.waiting
      }
      return true
    },
    isInscriptionAccepted(inscription) {
      return this.acceptedInscriptions.indexOf(inscription.id) >= 0
    },
    isInscriptionRefused(inscription) {
      return this.refusedInscriptions.indexOf(inscription.id) >= 0
    },
    isInscriptionWaiting(inscription) {
      return this.waitingInscriptions.indexOf(inscription.id) >= 0
    },
    isCancellationAccepted(inscription) {
      return this.acceptedCancellations.indexOf(inscription.id) >= 0
    },
    isCancellationRefused(inscription) {
      return this.refusedCancellations.indexOf(inscription.id) >= 0
    },
    doRefuseInscription(inscription, value) {
      let limitsChanged = false
      if (value) {
        this.refusedInscriptions.push(inscription.id)
        limitsChanged = this.doAcceptInscription(inscription, false)
      } else {
        const index = this.refusedInscriptions.indexOf(inscription.id)
        if (index >= 0) {
          this.refusedInscriptions.splice(index, 1)
        }
      }
      return limitsChanged
    },
    onRefuseInscription(inscription, value) {
      const limitsChanged = this.doRefuseInscription(inscription, value)
      if (limitsChanged) {
        this.loadLimits()
      }
    },
    doWaitingInscription(inscription, value) {
      let limitsChanged = false
      if (value) {
        this.waitingInscriptions.push(inscription.id)
        limitsChanged = this.doAcceptInscription(inscription, false)
      } else {
        const index = this.waitingInscriptions.indexOf(inscription.id)
        if (index >= 0) {
          this.waitingInscriptions.splice(index, 1)
        }
      }
      return limitsChanged
    },
    onWaitingInscription(inscription, value) {
      const limitsChanged = this.doWaitingInscription(inscription, value)
      if (limitsChanged) {
        this.loadLimits()
      }
    },
    doAcceptInscription(inscription, value) {
      let limitsChanged = false
      if (value) {
        const index = this.acceptedInscriptions.indexOf(inscription.id)
        if (index < 0) {
          this.acceptedInscriptions.push(inscription.id)
          limitsChanged = true
        }
        this.doRefuseInscription(inscription, false)
      } else {
        const index = this.acceptedInscriptions.indexOf(inscription.id)
        if (index >= 0) {
          this.acceptedInscriptions.splice(index, 1)
          limitsChanged = true
        }
      }
      return limitsChanged
    },
    onAcceptInscription(inscription, value) {
      const limitsChanged = this.doAcceptInscription(inscription, value)
      if (limitsChanged) {
        this.loadLimits()
      }
    },
    onAcceptCancellation(inscription, value) {
      if (value) {
        this.acceptedCancellations.push(inscription.id)
        this.onRefuseCancellation(inscription, false)
      } else {
        const index = this.acceptedCancellations.indexOf(inscription.id)
        if (index >= 0) {
          this.acceptedCancellations.splice(index, 1)
        }
      }
    },
    onRefuseCancellation(inscription, value) {
      if (value) {
        this.refusedCancellations.push(inscription.id)
        this.onAcceptCancellation(inscription, false)
      } else {
        const index = this.refusedCancellations.indexOf(inscription.id)
        if (index >= 0) {
          this.refusedCancellations.splice(index, 1)
        }
      }
    },
    resetChecks() {
      this.acceptedInscriptions = []
      this.refusedInscriptions = []
      this.waitingInscriptions = []
      this.acceptedCancellations = []
      this.refusedCancellations = []
    },
    isAllAccepted() {
      for (let inscription of this.displayedInscriptions) {
        if (inscription.cancelled) {
          if (!this.isInscriptionAccepted(inscription)) {
            return false
          }
        } else {
          if (!this.isCancellationAccepted(inscription)) {
            return false
          }
        }
      }
      return true
    },
    isAllRefused() {
      for (let inscription of this.displayedInscriptions) {
        if (inscription.cancelled) {
          if (!this.isInscriptionRefused(inscription)) {
            return false
          }
        } else {
          if (!this.isCancellationRefused(inscription)) {
            return false
          }
        }
      }
      return true
    },
    isAllWaiting() {
      for (let inscription of this.displayedInscriptions) {
        if (!inscription.cancelled) {
          if (!this.isInscriptionWaiting(inscription)) {
            return false
          }
        }
      }
      return true
    },
    onAcceptAll() {
      const checkAll = !this.isAllAccepted()
      this.resetChecks()
      for (let inscription of this.displayedInscriptions) {
        if (inscription.cancelled) {
          this.onAcceptCancellation(inscription, checkAll)
        } else {
          this.doAcceptInscription(inscription, checkAll)
        }
      }
      this.loadLimits()
    },
    onRefuseAll(value) {
      const checkAll = this.isAllRefused()
      this.resetChecks()
      for (let inscription of this.displayedInscriptions) {
        if (inscription.cancelled) {
          this.onRefuseCancellation(inscription, !checkAll)
        } else {
          this.doRefuseInscription(inscription, !checkAll)
        }
      }
      this.loadLimits()
    },
    onWaitingAll(value) {
      const checkAll = this.isAllWaiting()
      this.resetChecks()
      for (let inscription of this.displayedInscriptions) {
        if (!inscription.cancelled && !inscription.waiting && this.hasWaitingList(inscription)) {
          this.doWaitingInscription(inscription, !checkAll)
        }
      }
      this.loadLimits()
    },
    onUncheckAll() {
      this.resetChecks()
      this.loadLimits()
    },
    async onConfirmInscriptions() {
      this.startLoading(this.loadingName)
      try {
        const url = '/api/youth/confirm-inscriptions/'
        const backendApi = new BackendApi('post', url)
        const data = {
          'accepted_inscriptions': this.acceptedInscriptions,
          'refused_inscriptions': this.refusedInscriptions,
          'waiting_inscriptions': this.waitingInscriptions,
          'accepted_cancellations': this.acceptedCancellations,
          'refused_cancellations': this.refusedCancellations,
        }
        const resp = await backendApi.callApi(data)
        this.acceptedInscriptions = []
        this.refusedInscriptions = []
        this.waitingInscriptions = []
        this.acceptedCancellations = []
        this.refusedCancellations = []
        await this.addSuccess('Les inscriptions ont été prises en compte')
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
      await this.loadElements(
        this.dateFilter, this.periodFilter, this.youthHomeFilter, this.youthHomesFilter,
        this.individualFilter, this.selectedPaidFilterId, this.familyFilter, this.selectedStatusFilterId,
        this.selectedKindFilterId, this.seanceNameFilter
      )
    },
    getSeanceInscriptionLimits(seance) {
      return this.seanceLimits.get(seance.id) || null
    },
    async loadServices() {
      // TODO : refactor pour éviter de charger 2 fois les services (confirmation des inscriptions)
      // TODO: Attention aux effets de bords
      const url = '/portal/api/youth-home-services/'
      let backendApi = new BackendApi('get', url)
      try {
        let resp = await backendApi.callApi()
        this.services = resp.data.map(elt => makePortalService(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    getEntityDueInvoice(inscription) {
      const showUnpaidInvoicesOnConfirm = inscription.seance.youthHome.showUnpaidInvoicesOnConfirm
      const entity = inscription.entity
      if (showUnpaidInvoicesOnConfirm && this.entitiesWithDueInvoices.has(entity.id)) {
        return this.entitiesWithDueInvoices.get(entity.id)
      }
      return 0
    },
    getInscriptionFields(inscription) {
      let fields = []
      const entity = inscription.entity
      const individual = inscription.individual
      if (this.entityFields.has(entity.id)) {
        fields = fields.concat(this.entityFields.get(entity.id))
      }
      if (this.individualFields.has(individual.id)) {
        fields = fields.concat(this.individualFields.get(individual.id))
      }
      return fields
    },
    async loadEntitiesWithDueInvoices() {
      const url = '/api/entities-with-unpaid-invoices/'
      let backendApi = new BackendApi('post', url)
      try {
        const entityIds = this.inscriptions.filter(
          ins => ins.seance.youthHome.showUnpaidInvoicesOnConfirm
        ).map(ins => ins.entity.id)
        const entitiesWithDueInvoices = new Map()
        if (entityIds.length) {
          const data = { entities: distinctElements(entityIds), }
          let resp = await backendApi.callApi(data)
          for (const elt of resp.data) {
            entitiesWithDueInvoices.set(elt.id, elt.amount)
          }
        }
        this.entitiesWithDueInvoices = entitiesWithDueInvoices
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadInscriptionFields() {
      const url = '/api/people/field-values/confirm-inscriptions/'
      let backendApi = new BackendApi('post', url)
      try {
        const entityIds = this.inscriptions.map(elt => elt.entity.id)
        const individualIds = this.inscriptions.map(elt => elt.individual.id)
        const data = {
          entities: distinctElements(entityIds),
          individuals: distinctElements(individualIds),
        }
        let resp = await backendApi.callApi(data)
        const entityFields = new Map()
        const individualFields = new Map()
        for (const elt of resp.data.entities) {
          entityFields.set(elt.id, elt.fields)
        }
        for (const elt of resp.data.individuals) {
          individualFields.set(elt.id, elt.fields)
        }
        this.entityFields = entityFields
        this.individualFields = individualFields
        this.fieldsFilters = resp.data.filters
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadLimits() {
      this.startLoading(this.limitIndicatorLoadingName)
      let url = '/api/youth/seance-limits-check-for-confirm/'
      const data = {
        seances: this.seances.map(elt => elt.id),
        accepted: this.acceptedInscriptions,
      }
      const seanceLimits = new Map()
      let backendApi = new BackendApi('post', url)
      try {
        let resp = await backendApi.callApi(data)
        for (const elt of resp.data) {
          const limits = makeSeanceInscriptionLimit(elt)
          seanceLimits.set(limits.seanceId, limits)
        }
        this.seanceLimits = seanceLimits
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.limitIndicatorLoadingName)
    },
    onSeanceDateChanged(seanceDate) {
      this.dateFilter = seanceDate
      this.onFilterChanged(
        this.dateFilter, this.periodFilter, this.youthHomeFilter, this.youthHomesFilter, this.individualFilter,
        this.selectedPaidFilterId, this.familyFilter, this.selectedStatusFilterId, this.selectedKindFilterId,
        this.seanceNameFilter
      )
    },
    onPeriodChanged(period) {
      this.periodFilter = period
      this.onFilterChanged(
        this.dateFilter, this.periodFilter, this.youthHomeFilter, this.youthHomesFilter, this.individualFilter,
        this.selectedPaidFilterId, this.familyFilter, this.selectedStatusFilterId, this.selectedKindFilterId,
        this.seanceNameFilter
      )
    },
    onSeanceNameFilterChanged() {
      this.onFilterChanged(
        this.dateFilter, this.periodFilter, this.youthHomeFilter, this.youthHomesFilter, this.individualFilter,
        this.selectedPaidFilterId, this.familyFilter, this.selectedStatusFilterId, this.selectedKindFilterId,
        this.seanceNameFilter
      )
    },
    onOrderByChanged() {
      this.onFilterChanged(
        this.dateFilter, this.periodFilter, this.youthHomeFilter, this.youthHomesFilter, this.individualFilter,
        this.selectedPaidFilterId, this.familyFilter, this.selectedStatusFilterId, this.selectedKindFilterId,
        this.seanceNameFilter
      )
    },
    onYouthHomeChanged(youthHome) {
      this.youthHomeFilter = youthHome
      this.onFilterChanged(
        this.dateFilter, this.periodFilter, this.youthHomeFilter, this.youthHomesFilter, this.individualFilter,
        this.selectedPaidFilterId, this.familyFilter, this.selectedStatusFilterId, this.selectedKindFilterId,
        this.seanceNameFilter
      )
    },
    async loadInitial() {
      await this.loadElements(
        this.dateFilter, this.periodFilter, this.youthHomeFilter, this.youthHomesFilter, this.individualFilter,
        this.selectedPaidFilterId, this.familyFilter, this.selectedStatusFilterId, this.selectedKindFilterId,
        this.seanceNameFilter
      )
      await this.loadLimits()
      await this.loadEntitiesWithDueInvoices()
      await this.loadInscriptionFields()
    },
    onYouthHomesLoaded(youthHomes) {
      this.allYouthHomes = youthHomes
      this.youthHomesFilter = youthHomes
      this.onFilterChanged(
        this.dateFilter, this.periodFilter, this.youthHomeFilter, this.youthHomesFilter, this.individualFilter,
        this.selectedPaidFilterId, this.familyFilter, this.selectedStatusFilterId, this.selectedKindFilterId,
        this.seanceNameFilter
      )
      this.loadInitial()
      if (this.allowWaitingList) {
        this.checkToBeRefused()
      }
    },
    onYouthHomesChanged(youthHomes, initial) {
      this.youthHomesFilter = youthHomes
      this.onFilterChanged(
        this.dateFilter, this.periodFilter, this.youthHomeFilter, this.youthHomesFilter, this.individualFilter,
        this.selectedPaidFilterId, this.familyFilter, this.selectedStatusFilterId, this.selectedKindFilterId,
        this.seanceNameFilter
      )
      if (initial) {
        this.loadInitial()
      }
      if (this.allowWaitingList) {
        this.checkToBeRefused()
      }
    },
    onIndividualChanged(individual) {
      this.individualFilter = individual
      this.onFilterChanged(
        this.dateFilter, this.periodFilter, this.youthHomeFilter, this.youthHomesFilter, this.individualFilter,
        this.selectedPaidFilterId, this.familyFilter, this.selectedStatusFilterId, this.selectedKindFilterId,
        this.seanceNameFilter
      )
    },
    onFamilyChanged(family) {
      this.familyFilter = family
      this.onFilterChanged(
        this.dateFilter, this.periodFilter, this.youthHomeFilter, this.youthHomesFilter, this.individualFilter,
        this.selectedPaidFilterId, this.familyFilter, this.selectedStatusFilterId, this.selectedKindFilterId,
        this.seanceNameFilter
      )
    },
    onIndividualClicked(individual) {
      if (this.individualInitialId !== individual.id) {
        this.individualInitialId = individual.id
        this.onIndividualChanged(individual)
      }
    },
    onFamilyClicked(inscription) {
      if (this.familyInitialId !== inscription.entity.id) {
        this.familyInitialId = inscription.entity.id
        this.onFamilyChanged(inscription) // on triche en passant une inscription
      }
    },
    onPaidFilterChanged() {
      this.onFilterChanged(
        this.dateFilter, this.periodFilter, this.youthHomeFilter, this.youthHomesFilter, this.individualFilter,
        this.selectedPaidFilterId, this.familyFilter, this.selectedStatusFilterId, this.selectedKindFilterId,
        this.seanceNameFilter
      )
    },
    onRefresh() {
      this.onFilterChanged(
        this.dateFilter, this.periodFilter, this.youthHomeFilter, this.youthHomesFilter, this.individualFilter,
        this.selectedPaidFilterId, this.familyFilter, this.selectedStatusFilterId, this.selectedKindFilterId,
        this.seanceNameFilter
      )
    },
    onStatusFilterChanged() {
      if (
        (this.selectedStatusFilterId === StatusFilter.Waiting) &&
        (this.selectedPaidFilterId !== PaidFilter.All)
      ) {
        this.selectedPaidFilterId = PaidFilter.All
        this.addWarning('Le filtre paiement a été modifié pour afficher toutes les inscriptions')
      }
      this.onFilterChanged(
        this.dateFilter, this.periodFilter, this.youthHomeFilter, this.youthHomesFilter, this.individualFilter,
        this.selectedPaidFilterId, this.familyFilter, this.selectedStatusFilterId, this.selectedKindFilterId,
        this.seanceNameFilter
      )
    },
    getLinkToEntityUrl(entityId, individual = null) {
      let url = router.resolve(this.getLinkToEntity(entityId)).href
      if (individual) {
        url += '?individual=' + individual.id
      }
      return url
    },
    getLinkToEntity(entityId) {
      return { name: 'families-detail', params: { entityId: '' + entityId, }, }
    },
    showToBeRefused() {
      this.dateFilter = null
      this.periodFilter = null
      this.individualFilter = null
      this.selectedPaidFilterId = PaidFilter.All
      this.selectedKindFilterId = KindFilter.All
      this.familyFilter = null
      this.selectedStatusFilterId = StatusFilter.Refund
      this.onRefresh()
    },
    getFieldFilterValue(field) {
      return +(this.fieldsFiltersValues.get(field.id) || 0)
    },
    setFieldFilterValue(field, event) {
      this.fieldsFiltersValues.set(field.id, +event)
      this.fieldsFiltersValues = new Map(this.fieldsFiltersValues)
      this.onRefresh()
    },
  },
}
</script>

<style lang="less">
.verification-line {
  padding: 5px;
  background: #e0e0e0;
}
.verification-label {
  font-size: 1em;
  font-weight: bold;
}
.label {
  background: #f7f7f7;
  border-radius: 4px;
  display: inline-block;
  padding: 3px;
  font-size: 0.8em;
}
.filter-bar {
  background: #f7f7f7;
  margin: 10px 0;
  padding: 10px;
  border: solid 1px #888;
  border-radius: 4px;
}
.filter-bar-title {
  margin-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: solid 1px #888;
}
.badges-holder .badge {
  margin: 0 1px;
}
.checks-links a {
  font-size: 0.8em;
  display: inline-block;
  margin-top: 5px;
  margin-left: 5px;
  color: #222 !important;
  border: solid #222 1px;
  padding: 2px;
}
a.small-link{
  font-size: 0.8em;
  display: inline-block;
  color: #222 !important;
  border: solid #222 1px;
  padding: 1px;
  vertical-align: top;
}
.bold {
  display: inline-block;
  color: #222 !important;
  font-weight: bold;
  margin-right: 5px;
}
.ins-summary {
  padding: 3px;
  border: solid 1px #222;
  border-radius: 8px;
  background: #eee;
}
.legend-header {
  padding-bottom: 2px;
  border-bottom: solid 1px #ccc;
  margin-bottom: 5px;
}
.actions-holder label{
  white-space: nowrap;
}
.warning-message {
  border: solid 1px #444;
  background: #ffca22;
  padding: 5px;
}
.family-info {
  font-size: 12px;
}
</style>
